import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { useElementID } from '../../utils/ElementID';

export interface ILatLon {
  lat: number;
  lng: number;
}

export class MapMarkerInputValue {
  static empty() {
    return new MapMarkerInputValue('', '', undefined, undefined);
  }

  static fromValue(value?: ILatLon) {
    if (value === undefined) {
      return this.empty();
    }
    
    return new MapMarkerInputValue(
      value.lat.toFixed(4),
      value.lng.toFixed(4),
      value.lat,
      value.lng,
      value
    );
  }

  latAsString: string;
  lonAsString: string;
  latAsNumber?: number;
  lonAsNumber?: number;
  lastValidPosition?: ILatLon;

  constructor(
      latAsString: string,
      lonAsString: string,
      latAsNumber?: number,
      lonAsNumber?: number,
      value?: ILatLon
  ) {
    this.lastValidPosition = value;
    this.latAsString = latAsString;
    this.lonAsString = lonAsString;
    this.latAsNumber = latAsNumber;
    this.lonAsNumber = lonAsNumber;
  }

  get valid(): boolean {
    return this.latAsNumber !== undefined && this.lonAsNumber !== undefined;
  }

  withLatValue(value: string): MapMarkerInputValue {
    if (!/^[0-9.,]+$/.test(value)) {
      return new MapMarkerInputValue(value, this.lonAsString, undefined, this.lonAsNumber, this.lastValidPosition);
    }

    const latAsNumber = parseFloat(value.replace(',', '.'));
    if (Number.isNaN(latAsNumber)) {
      return new MapMarkerInputValue(value, this.lonAsString, undefined, this.lonAsNumber, this.lastValidPosition);
    } else {
      return new MapMarkerInputValue(value, this.lonAsString, latAsNumber, this.lonAsNumber, this.lonAsNumber === undefined ? undefined : {
        lat: latAsNumber,
        lng: this.lonAsNumber
      });
    }
  }

  withLonValue(value: string): MapMarkerInputValue {
    if (!/^[0-9.,]+$/.test(value)) {
      return new MapMarkerInputValue(this.latAsString, value, this.latAsNumber, undefined, this.lastValidPosition);
    }

    const lonAsNumber = parseFloat(value.replace(',', '.'));
    if (Number.isNaN(lonAsNumber)) {
      return new MapMarkerInputValue(this.latAsString, value, this.latAsNumber, undefined, this.lastValidPosition);
    } else {
      return new MapMarkerInputValue(this.latAsString, value, this.latAsNumber, lonAsNumber, this.latAsNumber === undefined ? undefined : {
        lat: this.latAsNumber,
        lng: lonAsNumber
      });
    }
  }
}

interface MapMarkerInputProps {
  labelColumns?: number;

  label?: string;
  position: MapMarkerInputValue;
  defaultCenter?: ILatLon;
  defaultZoom?: number;
  info?: string;
  readOnly?: boolean;

  onChange: (position: MapMarkerInputValue) => void;
}

export const MapMarkerInputMap = withGoogleMap((props: MapMarkerInputProps) => {
  const {
    position,
    defaultCenter = position.lastValidPosition,
    defaultZoom = 8,
    onChange,
    readOnly = false
  } = props;

  const handleDragEnd = (e: any) => {
    if (readOnly)
      return;
    
    onChange(MapMarkerInputValue.fromValue({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
  };

  const handleClick = (e: any) => {
    if (readOnly)
      return;
    
    onChange(MapMarkerInputValue.fromValue({ lat: e.latLng.lat(), lng: e.latLng.lng() }));
  };

  return (
    <GoogleMap defaultZoom={defaultZoom} center={defaultCenter} onClick={position.valid ? undefined : handleClick}>
      {position.lastValidPosition && (
        <Marker
          position={position.lastValidPosition}
          draggable={!readOnly}
          onDragEnd={handleDragEnd}
        />
      )}
    </GoogleMap>
  );
});

export const MapMarkerInput = (props: MapMarkerInputProps) => {
  const {
    labelColumns = 4,
    label,
    info,
    readOnly
  } = props;

  let labelColumnsCount = labelColumns;
  if (!label) {
    labelColumnsCount = 0;
  }
  const id = useElementID('input');
  return (
    <div className="form-group row">
      {((labelColumnsCount > 0) && <label htmlFor={id} className={`col-sm-${labelColumnsCount} control-label`}>{label}</label>)}
      <div className={`col-sm-${12 - labelColumnsCount}`}>
        <MapMarkerInputMap
          containerElement={<div style={{ height: 300 }} />}
          mapElement={<div style={{ height: `100%` }} />}
          readOnly={readOnly}
          {...props}
        />
        {info && <small className='form-text text-muted' style={{textAlign: "right"}}>{info}</small>}
      </div>
    </div>
  );
}
